import React, { Component } from 'react'
import Helmet from 'react-helmet'
import Layout from '../layout'
import SEO from '../components/SEO'
import config from '../../data/SiteConfig'

export default class NotFoundPage extends Component {

  render() {
    return (
      <Layout>
        <Helmet title={`Page not found – ${config.siteTitle}`} />
        <SEO />
        <div className="container">
          <div className="text-center">
            <h1>Page not found</h1>
          </div>
          <p>
            Sorry, we were not able to find the page you are looking for. Please report this error to <a href="mailto:mail@heysupratim.com"><span className="text">Supratim</span></a>
          </p>
        </div>
      </Layout>
    )
  }
}
